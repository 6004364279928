import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SignIn } from "./components/SignIn";
import { makeStyles } from "@mui/styles";
import { Logout } from "@mui/icons-material";
import { COLOR_BRAND } from "./consts";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    width: "100%",
    height: 40,
    alignItems: "center",
    position: "fixed"
  },
  root: {
    display: "block",
    flex: 1,
    width: "100%",
    paddingTop: 40,
    borderWidth: 0
  },
  logo: {
    height: "60%",
    width: "auto",
    marginLeft: 20
  },
  app_name: {
    marginLeft: 20,
    color: "#fff",
    borderLeft: "1px solid #fff",
    paddingLeft: 15,
    fontWeight: "bold"
  },
  logout: {
    position: "absolute",
    right: 20,
    color: "#fff",
    cursor: "pointer"
  }
}));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [customer, setCustomer] = useState({});
  const [redirectUrl, setRedirectUrl] = useState("");
  const classes = useStyles();
  const { innerWidth, innerHeight } = window;
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    init();

    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    // cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const init = async () => {
    const isSignedIn = localStorage.getItem('signedIn');
    console.info("isSignedIn: "+ isSignedIn);
    if(isSignedIn){
      const userData = localStorage.getItem('userData');
      const customer = JSON.parse(userData);
      setCustomer(customer);
      if (customer.id_user == 41) {
        localStorage.removeItem('signedIn');
        localStorage.removeItem('userData');
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
      }
    }
  }

  const signOut = async () => {
    setIsAuthenticated(false);
    setCustomer({});
    window.location.href = "/";
    localStorage.removeItem('signedIn');
  }

  const saveCustomerDetails = (data) => {
    setCustomer(data);
    localStorage.setItem('userData', JSON.stringify(data));
  }

  return (
    <Router>
      {isAuthenticated ? (
        <>
          <div className={classes.header} style={{ backgroundColor: isAuthenticated ? customer.color : COLOR_BRAND }}>
            <img src="images/logo_white.png" className={classes.logo}/>
            {isAuthenticated ? (
              <>
                <div className={classes.app_name}>{customer.name} &nbsp; <b style={{ fontSize: "11px" }}>(User: {customer.username})</b></div>
                <Logout className={classes.logout} onClick={() => { signOut(); }} />
              </>
            ) : null}
          </div>
          <iframe className={classes.root} style={{ height: innerHeight-40 }} src={customer.redirect} />
        </>
      ) : (
        <Routes>
          <Route path="" element={<SignIn setIsAuthenticated={setIsAuthenticated} saveCustomerDetails={saveCustomerDetails} />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;